import { LifeCycles, registerApplication, start } from 'single-spa';

registerApplication({
  name: '@bv/middleware-old',
  app: () => System.import<LifeCycles>('@bv/middleware-old'),
  activeWhen: () => true,
});

registerApplication({
  name: '@bv/global-not-found',
  app: () => System.import<LifeCycles>('@bv/global-not-found'),
  activeWhen: ['/pagina-nao-encontrada'],
});

registerApplication({
  name: '@bv/public-auth',
  app: () => System.import<LifeCycles>('@bv/public-auth'),
  activeWhen: ['/login'],
});

registerApplication({
  name: '@bv/public-pre-invite',
  app: () => System.import<LifeCycles>('@bv/public-pre-invite'),
  activeWhen: ['/pre-convite'],
});

registerApplication({
  name: '@bv/public-review',
  app: () => System.import<LifeCycles>('@bv/public-review'),
  activeWhen: ['/avaliar'],
});

registerApplication({
  name: '@bv/public-registration',
  app: () => System.import<LifeCycles>('@bv/public-registration'),
  activeWhen: ['/convidado'],
});

registerApplication({
  name: '@bv/optics-exchange-prescription-old',
  app: () => System.import<LifeCycles>('@bv/optics-exchange-prescription-old'),
  activeWhen: ['/otica/trocar-receita'],
});

// Importando o styleguide antes de iniciar o roteador, para que o tailwind seja carregado antes de qualquer aplicação
start({
  urlRerouteOnly: true,
});
