import { LifeCycles, registerApplication, start } from 'single-spa';

registerApplication({
  name: '@bv/middleware-old',
  app: () => System.import<LifeCycles>('@bv/middleware-old'),
  activeWhen: () => true,
});

registerApplication({
  name: '@bv/not-found',
  app: () => System.import<LifeCycles>('@bv/not-found'),
  activeWhen: ['/pagina-nao-encontrada'],
});

registerApplication({
  name: '@bv/login-old',
  app: () => System.import<LifeCycles>('@bv/login-old'),
  activeWhen: ['/login'],
});

registerApplication({
  name: '@bv/pre-invite',
  app: () => System.import<LifeCycles>('@bv/pre-invite'),
  activeWhen: ['/pre-convite'],
});

registerApplication({
  name: '@bv/invited-old',
  app: () => System.import<LifeCycles>('@bv/invited-old'),
  activeWhen: ['/convidado'],
});

registerApplication({
  name: '@bv/optics-exchange-prescription-old',
  app: () => System.import<LifeCycles>('@bv/optics-exchange-prescription-old'),
  activeWhen: ['/otica/trocar-receita'],
});

// Importando o styleguide antes de iniciar o roteador, para que o tailwind seja carregado antes de qualquer aplicação
start({
  urlRerouteOnly: true,
});
